export function useLoaderSvgViewBox() {
    return '0, 0, 100, 100'
}

export function useLoaderSvg() {
    return `<animate begin="0s" keySplines="0 0.5 0.5 1" values="6;0" keyTimes="0;1" calcMode="spline" dur="0.4166666666666667s" repeatCount="indefinite" attributeName="r"></animate>
    <animate begin="0s" values="#111111;#111111;#111111;#111111;#111111" keyTimes="0;0.25;0.5;0.75;1" calcMode="discrete" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="fill"></animate>
</circle><circle fill="#111111" r="10" cy="50" cx="16">
  <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;6;6;6" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#111111" r="10" cy="50" cx="50">
  <animate begin="-0.4166666666666667s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;6;6;6" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-0.4166666666666667s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#111111" r="10" cy="50" cx="84">
  <animate begin="-0.8333333333333334s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;6;6;6" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-0.8333333333333334s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><circle fill="#111111" r="10" cy="50" cx="16">
  <animate begin="-1.25s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;6;6;6" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="r"></animate>
  <animate begin="-1.25s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.6666666666666667s" repeatCount="indefinite" attributeName="cx"></animate>
</circle><g></g></g>`
}